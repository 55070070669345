var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "portfolio-table"
  }, [_c('div', {
    staticClass: "portfolio-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "portfolioTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(investedCompanyName)",
      fn: function fn(rowData) {
        return [_c('user-presentation', {
          staticClass: "table__user-presentation",
          attrs: {
            "name": rowData.item.investedCompanyName,
            "image": rowData.item.investedCompanyLogo
          }
        })];
      }
    }, {
      key: "cell(investedFundName)",
      fn: function fn(rowData) {
        return [_c('user-presentation', {
          staticClass: "table__user-presentation",
          attrs: {
            "name": rowData.item.investedFundName,
            "image": rowData.item.investedFundLogo
          }
        })];
      }
    }, {
      key: "cell(industry)",
      fn: function fn(rowData) {
        return [_c('span', [_vm._v(_vm._s(_vm.getIndustryLabel(rowData.item.industry)))])];
      }
    }, {
      key: "cell(stage)",
      fn: function fn(rowData) {
        return [_c('span', [_vm._v(_vm._s(_vm.getStageLabel(rowData.item.stage)))])];
      }
    }, {
      key: "cell(seriesOfSharesName)",
      fn: function fn(rowData) {
        return [_c('span', [_vm._v(_vm._s(rowData.item.seriesOfSharesName ? rowData.item.seriesOfSharesName : 'N/A'))])];
      }
    }, {
      key: "cell(instrumentClass)",
      fn: function fn(rowData) {
        return [_c('span', [_vm._v(_vm._s(_vm.getInstrumentClassLabel(rowData.item.instrumentClass)))])];
      }
    }, {
      key: "cell(instrumentCount)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.instrumentCount, 0)))])])];
      }
    }, {
      key: "cell(dealCount)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.dealCount, 0)))])])];
      }
    }, {
      key: "cell(instrumentType)",
      fn: function fn(rowData) {
        return [_c('span', [_vm._v(_vm._s(_vm.getInstrumentTypeLabel(rowData.item.instrumentType)))])];
      }
    }, {
      key: "cell(nominalValue)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.nominalValue.value, 2)) + " ")])])];
      }
    }, {
      key: "cell(total)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.total.value, 2)) + " ")])])];
      }
    }, {
      key: "cell(investedFundCapitalisation)",
      fn: function fn(rowData) {
        var _rowData$item$investe, _rowData$item$investe2;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item$investe = rowData.item.investedFundCapitalisation) === null || _rowData$item$investe === void 0 ? void 0 : _rowData$item$investe.value, 2)) + " " + _vm._s((_rowData$item$investe2 = rowData.item.investedFundCapitalisation) === null || _rowData$item$investe2 === void 0 ? void 0 : _rowData$item$investe2.currency))])])];
      }
    }, {
      key: "cell(portfolioValuation)",
      fn: function fn(rowData) {
        var _rowData$item$portfol, _rowData$item$portfol2;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item$portfol = rowData.item.portfolioValuation) === null || _rowData$item$portfol === void 0 ? void 0 : _rowData$item$portfol.value, 2)) + " " + _vm._s((_rowData$item$portfol2 = rowData.item.portfolioValuation) === null || _rowData$item$portfol2 === void 0 ? void 0 : _rowData$item$portfol2.currency))])])];
      }
    }, {
      key: "cell(invested)",
      fn: function fn(rowData) {
        var _rowData$item$investe3, _rowData$item$investe4;

        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")((_rowData$item$investe3 = rowData.item.invested) === null || _rowData$item$investe3 === void 0 ? void 0 : _rowData$item$investe3.value, 2)) + " " + _vm._s((_rowData$item$investe4 = rowData.item.invested) === null || _rowData$item$investe4 === void 0 ? void 0 : _rowData$item$investe4.currency))])])];
      }
    }, {
      key: "cell(investment)",
      fn: function fn(rowData) {
        return [_c('div', {
          class: ['table__arrow', 'd-flex', 'align-items-center', 'pl-4 pr-4', rowData.item.portfolioValuation == undefined ? 'justify-content-between' : 'justify-content-end']
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.investment.value, 2)) + " " + _vm._s(rowData.item.investment.currency) + " ")]), rowData.item.portfolioValuation == undefined ? _c('div', {
          staticClass: "arrow",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_c('sygni-arrow-button', {
          attrs: {
            "open": rowData.item._showDetails,
            "rotate-on-click": true
          }
        })], 1) : _vm._e()])];
      }
    }, {
      key: "cell(reportStatus)",
      fn: function fn(rowData) {
        var _rowData$item$reportS;

        return [_c('div', {
          staticClass: "table__arrow d-flex justify-content-end align-items-center pl-4 pr-4"
        }, [_c('span', [_vm._v(_vm._s((_rowData$item$reportS = rowData.item.reportStatus) === null || _rowData$item$reportS === void 0 ? void 0 : _rowData$item$reportS.toUpperCase()))]), rowData.item.reportStatus != undefined ? _c('div', {
          staticClass: "arrow",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_c('sygni-arrow-button', {
          attrs: {
            "open": rowData.item._showDetails,
            "rotate-on-click": true
          }
        })], 1) : _vm._e()])];
      }
    }, {
      key: "row-details",
      fn: function fn(rowData) {
        return [_c('portfolio-inner-table', {
          attrs: {
            "items": rowData.item.details
          },
          on: {
            "openDetailsModal": _vm.openDetailsModal
          }
        })];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center text-danger spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }