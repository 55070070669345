



































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {Statues} from "@/shared/interfaces/Statues";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import SygniInnerTable from "@/components/table/SygniInnerTable.vue";
import { Product} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import { BvTableField } from 'bootstrap-vue';

@Component({
  components: {SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse }
})
export default class PortfolioInnerTable extends SygniInnerTable<Product> {

  innerTableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = []

  innerTableFundFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'code', sortable: true, class: 'company text-left', label: 'Code'},
    { key: 'programme', sortable: true, label: 'Programme' },
    { key: 'capitalization', sortable: true, label: 'Capilatisation' },
    { key: 'investment', sortable: true, label: 'Investment' },
    { key: 'invested', sortable: true, label: 'Invested' },
    { key: 'dealCount', sortable: true, label: 'Deal count' },
    { key: 'porfolioValuation', sortable: true, label: 'Portfolio valuation' },
    { key: 'reportStatus', sortable: true, label: 'Report status' },
  ]

  innerTableCompanyFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'code', sortable: true, class: 'company text-left', label: 'Code'},
    { key: 'fundName', sortable: true, label: 'Fund name'},
    { key: 'industry', sortable: true, label: 'Industry' },
    { key: 'stage', sortable: true, label: 'Stage' },
    { key: 'seriesOfSharesName', sortable: true, label: 'Series of shares' },
    { key: 'instrumentClass', sortable: true, label: 'Instrument class' },
    { key: 'instrumentType', sortable: true, label: 'Instrument type' },
    { key: 'instrumentCount', sortable: true, label: 'Instrument count' },
    { key: 'nominalValue', sortable: true, label: 'Nominal value' },
    { key: 'totalValue', sortable: true, label: 'Total value' },
    { key: 'invested', sortable: true , label: 'Invested' },
  ]

  statusClass(status: Statues) {
    switch(status) {
      case(Statues.active): {
        return 'primary';
      }
      case(Statues.invited): {
        return 'success';
      }
      case(Statues.awaiting): {
        return 'success';
      }
      case(Statues.pending): {
        return 'black';
      }
      case(Statues.rejected): {
        return 'danger';
      }
      case(Statues.declined): {
        return 'disabled';
      }
    }
    return '';
  }

  statusText(status: Statues): string {
    switch(status) {
      case(Statues.active): {
        return 'Active';
      }
      case(Statues.invited): {
        return 'Invited';
      }
      case(Statues.awaiting): {
        return 'Awaiting';
      }
      case(Statues.pending): {
        return 'Pending';
      }
      case(Statues.rejected): {
        return 'Rejected';
      }
      case(Statues.declined): {
        return 'Declined';
      }
    }
    return '';
  }

  get isCompanyType() {
    return this.$route.name == 'investor-portfolio-by-company';
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  beforeMount() {
    this.innerTableFields = this.isCompanyType ? this.innerTableCompanyFields : this.innerTableFundFields;
  }
}

